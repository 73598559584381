import { globalStore } from "@/stores/global";
import { watchEffect, inject, onMounted } from "vue";

export const useGetUserInformations = () => {
  const backendApi = inject("backendApi");
  const store = globalStore();
  backendApi.defaults.headers.common["Authorization"] = "Bearer " + store.accessToken;

  const updateUserDatas = async () => {
    const accessToken = store.accessToken;
    if (accessToken) {
      const { data: dataUser } = await backendApi.get("/discord/user");
      const { data: dataGuild } = await backendApi.get("/discord/guilds");

      store.userInformations = dataUser.data;
      store.guilds = dataGuild.data.guilds;
    }
  };

  watchEffect(() => store.accessToken, updateUserDatas);

  onMounted(()=> {
    updateUserDatas()
  });
};
