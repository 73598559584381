<template>
  <ModalWrapper name="Buy">
    <Wrapper>
      <div class="w-[319px] xl:w-[1144px] xl:h-[601px] ml-4 mr-4">
        <div @click="closeModal()" class="pt-10 w-[26.5px] flex items-center cursor-pointer">
          <img src="/icon/arrow-left.png" alt />
          <span class="pl-2 text-xs font-normal">Back</span>
        </div>
        <div class="wrapper--header lg:flex items-center mb-7">
          <p class="mr-4 text-3xl font-semibold my-5 lg:my-0">Payement</p>
          <div
            class="flex w-full lg:w-fit lg:px-5 h-16 bg-[#33364A] rounded-2xl justify-center items-center mt-2"
          >
            <p class="text-lg my-3 lg:my-0">
              Thales Argent
              <span class="text-sm">70€</span>
              <span class="font-thin text-sm">/mois</span>
            </p>
          </div>
        </div>
        <div class="wrapper--options flex items-center mb-8">
          <Cta class="!px-8 mr-4">Paypal</Cta>
          <Cta class="!px-8">GPay</Cta>
        </div>
        <p class="mb-3 flex items-center">
          <span class="bg-[#9794AE] trait--separation h-px w-[32px] block mx-3"></span> Ou payez par
          carte <span class="bg-[#9794AE] trait--separation h-px w-[32px] block mx-3"></span>
        </p>
        <div>
          <label class="block text-white text-sm font-bold mb-2" for="email">Email</label>
          <input
            class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full max-w-[320px]"
            id="username"
            type="text"
            placeholder="Email"
            v-model="mail"
          />
        </div>
        <div class="relative max-w-[320px]">
          <StripeElements
            class="pt-5"
            v-if="stripeLoaded"
            v-slot="{ elements, instance }"
            ref="elms"
            :stripe-key="stripeKey"
            :instance-options="instanceOptions"
            :elements-options="elementsOptions"
          >
            <StripeElement ref="card" :elements="elements" :options="cardOptions" />
          </StripeElements>
        </div>
        <Cta class="mt-5" @click="ApiCallBuy"> Purchase </Cta>
      </div>
    </Wrapper>
  </ModalWrapper>
</template>

<script setup>
import Cta from "@/components/Cta.vue";
import ModalWrapper from "@/components/ModalWrapper.vue";
import Wrapper from "@/components/Modals/Wrapper.vue";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { useStripe } from "vue-use-stripe";
import { loadStripe } from "@stripe/stripe-js";
import { ref, onBeforeMount, onMounted, inject } from "vue";
import { globalStore } from "@/stores/global";

const backendApi = inject("backendApi");

const stripeKey = ref(
  "pk_test_51KajvhFlENaExLowLxThNmdWvxIVNmyC1kzzvmeTWvaokXhRl01L1Pd4B3RdILSS2JLAqYneRDXuBDdRvpjeX6pg005kM95znm"
); // test key
let clientSecretRef = ref("");
const instanceOptions = ref({
  // https://stripe.com/docs/js/initializing#init_stripe_js-options
});
const elementsOptions = ref({
  // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
});

// https://stripe.com/docs/stripe.js#element-options
const cardOptions = ref({
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "white",
      fontWeight: "500",
      lineHeight: "16px",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
    },
  },
});

const stripeLoaded = ref(false);
const card = ref();
const elms = ref();
const mail = ref("");

const Stripe = useStripe({
  key: stripeKey.value,
});

let { userInformations, accessToken, guildId } = globalStore();

function pay() {
  // Get stripe element
  const cardElement = card.value.stripeElement;
  // Access instance methods, e.g. createToken()
  elms.value.instance.createToken(cardElement).then((result) => {
    // Handle result.error or result.token
    console.log(result);
    // console.log("toto");
  });
}

onBeforeMount(() => {
  const stripePromise = loadStripe(stripeKey.value);
  stripePromise.then(() => {
    stripeLoaded.value = true;
  });
});

const ApiCallBuy = () => {
  backendApi
    .post("/payment/subscription", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: {
        email: mail.value,
        guildId: `${guildId}`,
        subscriptionId: 1,
      },
    })
    .then((res) => {
      const { pubkey, clientSecret, paymentIntentId, url } = res.data;
      // stripeKey.value = pubkey;

      if (clientSecret) {
        clientSecretRef.value = clientSecret;
        const { confirmCardPayment } = Stripe.stripe.value,
          cardElement = card.value.stripeElement;
        console.log(JSON.stringify(cardElement));
        confirmCardPayment(clientSecret, {
          payment_method: {
            card: StripeElement.props.elements,
          },
        }).then((result) => {
          console.log(result.error);
          // if (result.paymentIntent.id) {
          //   fetch(`https://aquaverse-thales-backend-develop-k5rhssucwa-ey.a.run.app/payment/subscription-confirm/${result.error.paymentIntent.id}`,{
          //       method: "GET",
          //       headers: {
          //         "Content-Type": "application/json",
          //         Authorization: `Bearer ${accessToken}`,
          //       }}).then((res) => res.json()).then((res) => {
          //         console.log(res.paid);
          //   });
          // }
        });
      }
    });
};
</script>
