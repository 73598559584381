<template>
  <Transition name="fade">
    <div
      v-if="currentModal === name"
      class="modal w-[100vw] h-[100vh] backdrop-blur-lg fixed top-0 left-0 z-10"
    >
      <div ref="modal" class="flex justify-center items-center h-full modalWrapper">
        <div class="table modal-wrapper-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, onBeforeMount, computed, onUnmounted, defineProps, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useModal } from "@/hooks/useCloseModal";

const { close } = useModal();

const currentModal = computed(() => route.query.modalOpen);
const ModalEvent = ref(null);
const modal = ref(null);

const route = useRoute();

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  closeModal : {
    type: Boolean,
    default: true
  }
});

let body = document.querySelector("body");
watchEffect(() => {
  if (currentModal.value) {
    body.style["overflow-y"] = "clip";
  } else {
    body.style["overflow-y"] = "auto";
  }
});
onBeforeMount(() => {
  ModalEvent.value = document.addEventListener("click", ({ target }) => {
    if (
      modal.value &&
      target.className === modal.value.className &&
      !target.closest(".modal-wrapper-content")
    ) {
      if (currentModal.value && !props.closeModal) {
        close();
      }
    }
  });
});

onUnmounted(() => {
  document.removeEventListener("mousedown", ModalEvent.value, true);
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
