import { defineStore } from 'pinia'

export const globalStore = defineStore('global', {
  state: () => {
    return {
      currentModal: '',
      accessToken: '',
      userInformations: {},
      guilds: [],
      guildId: '',
		}
  },
  persist: true,
  actions: {
  },
})
