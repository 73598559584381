import { createWebHistory, createRouter } from "vue-router";
// import twitterLogin from "../views/twitter-login.vue";
// import Comfirmation from "../views/twitter-login.vue";
import Home from "../views/Home.vue";
import Leaderboard from "../views/Leaderboard.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return {
        name: "Leaderboard",
        params: {
          number_page: 1,
          guildId: "980874884282466345",
        },
      };
    },
  },
  {
    path: "/leaderboard/page/:number_page/:guildId",
    name: "Leaderboard",
    component: Leaderboard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
