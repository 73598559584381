import config from "../config.yaml";
export function getEnvVariables() {
  const currentUrl = window.location.href;
  let env = "production";

  if (currentUrl.includes("localhost") || currentUrl.includes("develop")) {
    env = "dev";
  } else if (currentUrl.includes("staging")) {
    env = "staging";
  } else {
    env = "production";
  }

  return env;
}

export function getConfig(doc = config) {
  const conf = doc[getEnvVariables()];

// eslint-disable-next-line no-restricted-syntax
  for (const key in doc) {
    if (!(typeof doc[key] === "object")) {
      conf[key] = doc[key];
    }
  }
  return conf;
}
