<template>
	<div class="flex flex-col items-center relative sm:justify-center sm:flex-row sm:items-stretch">
		<div
			class="bg-white rounded-[24px] min-w-[40px] block text-[#33374A] px-6 max-w-[382px] relative sm:mr-7 mb-6 sm:mb-0 sm:max-w-[356px] w-full"
		>
			<div
				class="bg-[#ABB4CA] absolute right-0 mt-5 mr-5 px-3 py-2 rounded-full text-white font-bold flex items-center"
			>
				<img src="@/assets/Home/gemme-argent.png" class="px-1 h-[18px]" alt="silver-icon" />
				<div class="pr-2 table leading-none py-1 relative top-[1px] pl-1 text-[10px]">Thales Silver</div>
			</div>
			<div class="pt-20 pb-6">
				<p class="text-4xl font-bold">
					{{ statePriceSilverPlan }}$
					<span class="text-[17px] font-semibold">/mouth</span>
				</p>
			</div>
			<p class="text-[28px] font-bold">Thales Silver</p>
			<p
				class="pt-2 text-[#ABB4CA] font-normal text-[15px]"
			>Upgrade your discord server and social media engagement.</p>
			<div class="py-4">
				<div class="flex pt-2">
					<img src="/home/check-circle.png" class="pr-3" alt />
					<p class="font-normal">Six essential features</p>
				</div>
			</div>
      <!-- TODO: uncomment when silver plan is ready  -->
			<!-- <div class="pt-4">
				<p class="text-[15px] font-bold">Option :</p>
			</div>
			<div class="pt-4 flex">
				<span class="min-w-[26px] h-[26px] block mr-3 cursor-pointer" @click="selectOptioSilcerPlan">

					<img class="w-[26px] block" :src="stateSilverPlan ? '/home/option-icon.png' : '/home/option-icon-true.png'" alt />
				</span>
				<p class="text-sm font-medium">
					Integrate your branding with
					<br />the bot and customize its
					<br class="sm:hidden" />commands.
				</p>
			</div> -->
			<div class="pt-28 w-[88%] mx-auto pb-5">
				<span class="mx-auto">
					<Cta class="w-full px-10 mx-auto">
						<span class="text-white">Select this option</span>
					</Cta>
				</span>
			</div>
		</div>

		<div class="bg-gray-3 rounded-[24px] min-w-[40px] block text-white px-6 max-w-[382px] relative h-[526.5px] sm:max-w-[356px] w-full" >
			<div
				class="w-[141px] h-[32px] bg-[#474A5C] absolute right-0 mt-5 mr-5 p-2 rounded-full text-white font-bold flex items-center"
			></div>
			<div class="pt-20 pb-6">
				<p class="text-4xl font-bold flex">
					<span class="w-[88px] h-7 bg-[#474A5C] block rounded-full"></span>
					<span class="text-[17px] font-semibold pl-2">/mouth</span>
				</p>
			</div>
			<p class="text-[28px] font-bold">Comming soon</p>
			<div class="pt-2">
				<span class="pt-2 bg-[#474A5C] h-9 rounded-full block" />
			</div>
			<section class="pt-7">
				<div class="pt-4 flex items-center" v-for="i in 2" :key="i + '_comming_soon'" >
					<span>
						<img src="/home/check-circle-transparent.png" alt="">
					</span>
					<span class="w-full pl-2">
						<span class="pt-2 bg-[#474A5C] h-5 rounded-full block" />
					</span>
				</div>
			</section>

			<span class="mt-[124px] bg-[#474A5C] h-11 rounded-full block" />

		</div>
	</div>
</template>

<script setup>
import Cta from "@/components/Cta.vue";
import { ref, watch } from "vue"

const stateSilverPlan = ref(true);
const statePriceSilverPlan = ref('78');
const selectOptioSilcerPlan = () => stateSilverPlan.value = !stateSilverPlan.value;
// watch silver plan price
watch(() => stateSilverPlan.value, (newValue) => statePriceSilverPlan.value = newValue ? '78' : '110');

</script>
