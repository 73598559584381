<template>
  <span class="relative block">
    <label
      :for="value"
      class="items-center bg-[#5A30E5] w-[26px] h-[26px] rounded-full relative flex justify-center cursor-pointer"
    >
      <input :id="value" v-model="modelValue" :value="value" type="radio" class="form-radio" />
      <div class="wrapper relative bg-white rounded-full block w-[15px] h-[15px]">
        <div
          class="absolute left-0 right-0 top-0 bottom-0 m-auto rounded-full block w-[11px] h-[11px] transition-colors duration-300"
          :class="[modelValue == value ? 'bg-white scale-95 border-[2px] border-[#5A30E5]' : 'bg-[#5A30E5]']"
        ></div>
      </div>
    </label>
  </span>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  'modelValue': String,
  'value': String,
})

const modelValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>

<style lang="scss" scoped>
input[type="radio"] {
  @apply w-0 absolute left-0 right-[19px] top-[-8px] bottom-0 m-auto;
}
</style>
