import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "@stripe/stripe-js";
import "./fonts.scss";
import "./styles/stripe.scss";
import axios from "axios";

import { getConfig } from "./helpers";
let config = getConfig();

const ENV = {
  install(app) {
    app.provide("ENV", config);
  },
};

const $Api = {
  install(app) {
    let backendApi = axios.create({
      baseURL: config?.BACK_END_URI,
    });

    app.provide("backendApi", backendApi);
  },
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App).use(router).use($Api).use(ENV).use(pinia).use().mount("#app");
