import { defineStore } from 'pinia'

export const ModalFlow = defineStore('ModalFlow', {
  state: () => {
    return { 
      step: '',
      serverChoose: '',
		}
  },
  persist: true,
  actions: {},
})