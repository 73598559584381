<template>
  <div id="leadboard">
    <div class="leadboard--hero">
      <div class="wrapper__leadboard--hero--content lg:flex">
        <div class="w-full lg:w-1/2 flex flex-col justify-center mt-8">
          <!-- <BackToPage content="Back to home" redirection=""/> -->
          <h1>Leaderboard</h1>
          <p class="max-w-[465px] tracking-widest">
            Interact with your community by providing a unique and customizable gamified experience
          </p>
        </div>

        <div class="w-full lg:w-1/2">
          <img class="mx-auto lg:mx-0" src="@/assets/Home/leaderboard.png" alt="" />
        </div>
      </div>
    </div>
    <div class="leadboard--tools" id="leaderBoardLink">
      <div class="flex items-center justify-between mb-4 lg:mb-0 flex-wrap opacity-0 pointer-events-none">
        <div
          class="switch-time bg-bg-3 w-full md:w-fit rounded-2xl flex mt-3"
          @click="switchTimeState()"
        >
          <button
            class="transition-colors duration-500 text-xs px-4 py-3 w-full justify-center md:justify-start md:w-fit rounded-2xl flex items-center h-12"
            :class="allTimeComputed"
          >
            <span>
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9999 5.85002C8.60335 5.85002 5.8499 8.60347 5.8499 12C5.8499 15.3966 8.60335 18.15 11.9999 18.15C15.3965 18.15 18.1499 15.3966 18.1499 12C18.1499 8.60347 15.3965 5.85002 11.9999 5.85002ZM4.1499 12C4.1499 7.66459 7.66447 4.15002 11.9999 4.15002C16.3353 4.15002 19.8499 7.66459 19.8499 12C19.8499 16.3355 16.3353 19.85 11.9999 19.85C7.66447 19.85 4.1499 16.3355 4.1499 12Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0001 7.40002C12.3315 7.40002 12.6001 7.66865 12.6001 8.00002L12.6001 12C12.6001 12.3314 12.3315 12.6 12.0001 12.6C11.6687 12.6 11.4001 12.3314 11.4001 12L11.4001 8.00002C11.4001 7.66865 11.6687 7.40002 12.0001 7.40002Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6001 12C15.6001 12.3313 15.3315 12.6 15.0001 12.6H12.0001C11.6687 12.6 11.4001 12.3313 11.4001 12C11.4001 11.6686 11.6687 11.4 12.0001 11.4H15.0001C15.3315 11.4 15.6001 11.6686 15.6001 12Z"
                  fill="white"
                />
              </svg> </span
            ><span>All time</span>
          </button>
          <button
            class="transition-colors duration-500 text-xs px-4 py-3 w-full justify-center md:justify-start md:w-fit rounded-2xl flex items-center h-12"
            :class="lastDaysComputed"
          >
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 5.7H8C6.72975 5.7 5.7 6.72975 5.7 8V16C5.7 17.2703 6.72975 18.3 8 18.3H16C17.2703 18.3 18.3 17.2703 18.3 16V8C18.3 6.72975 17.2703 5.7 16 5.7ZM8 4C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4H8Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 9.7H8C6.72975 9.7 5.7 10.7297 5.7 12V16C5.7 17.2703 6.72975 18.3 8 18.3H16C17.2703 18.3 18.3 17.2703 18.3 16V12C18.3 10.7297 17.2703 9.7 16 9.7ZM8 8C5.79086 8 4 9.79086 4 12V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V12C20 9.79086 18.2091 8 16 8H8Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.9999 6.84998C8.53046 6.84998 8.1499 6.46942 8.1499 5.99998V2.99998C8.1499 2.53053 8.53046 2.14998 8.9999 2.14998C9.46934 2.14998 9.8499 2.53053 9.8499 2.99998V5.99998C9.8499 6.46942 9.46934 6.84998 8.9999 6.84998Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.9999 6.84998C14.5305 6.84998 14.1499 6.46942 14.1499 5.99998V2.99998C14.1499 2.53053 14.5305 2.14998 14.9999 2.14998C15.4693 2.14998 15.8499 2.53053 15.8499 2.99998V5.99998C15.8499 6.46942 15.4693 6.84998 14.9999 6.84998Z"
                  fill="white"
                />
                <path
                  d="M8 13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13V15C12 15.5523 11.5523 16 11 16H9C8.44772 16 8 15.5523 8 15V13Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Last 30 days</span>
          </button>
        </div>
        <button
          class="transition-colors duration-500 hover:bg-[#5A30E5] text-xs px-4 py-3 w-1/2 md:w-fit rounded-2xl bg-bg-3 justify-center md:justify-start flex items-center h-12 mt-3 lg:ml-4"
        >
          <span>Sort by</span>
          <svg
            class="ml-2"
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1499 3.00002C10.1499 2.53058 10.5305 2.15002 10.9999 2.15002H18.9999C19.4693 2.15002 19.8499 2.53058 19.8499 3.00002C19.8499 3.46947 19.4693 3.85002 18.9999 3.85002H10.9999C10.5305 3.85002 10.1499 3.46947 10.1499 3.00002Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.149902 12C0.149902 11.5306 0.53046 11.15 0.999902 11.15H8.9999C9.46935 11.15 9.8499 11.5306 9.8499 12C9.8499 12.4695 9.46935 12.85 8.9999 12.85H0.999902C0.53046 12.85 0.149902 12.4695 0.149902 12Z"
              fill="white"
            />
            <path
              d="M7 3C7 4.65685 5.65685 6 4 6C2.34315 6 1 4.65685 1 3C1 1.34315 2.34315 0 4 0C5.65685 0 7 1.34315 7 3Z"
              fill="white"
            />
            <path
              d="M19 12C19 13.6569 17.6569 15 16 15C14.3431 15 13 13.6569 13 12C13 10.3431 14.3431 9 16 9C17.6569 9 19 10.3431 19 12Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div class="search-rank mb-11">
        <p>Search my rank</p>

        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.1369 15.5737C13.5873 15.5737 15.5737 13.5873 15.5737 11.1369C15.5737 8.68645 13.5873 6.7 11.1369 6.7C8.68645 6.7 6.7 8.68645 6.7 11.1369C6.7 13.5873 8.68645 15.5737 11.1369 15.5737ZM11.1369 17.2737C14.5262 17.2737 17.2737 14.5262 17.2737 11.1369C17.2737 7.74757 14.5262 5 11.1369 5C7.74757 5 5 7.74757 5 11.1369C5 14.5262 7.74757 17.2737 11.1369 17.2737Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.9194 14.9193C14.5875 15.2513 14.5875 15.7895 14.9194 16.1214L17.3991 18.6011C17.731 18.933 18.2692 18.933 18.6011 18.6011C18.9331 18.2691 18.9331 17.731 18.6011 17.399L16.1215 14.9193C15.7895 14.5874 15.2513 14.5874 14.9194 14.9193Z"
              fill="white"
            />
          </svg>

          <input type="text" v-model="user_search" placeholder="Enter your nickname" />
        </div>
      </div>
    </div>

    <div class="rank-legends lg:flex px-4 text-gray-4 text-base mb-6 hidden pl-[10%]">
      <p class="name w-full max-w-md">Profile</p>
      <p class="level w-48">Level</p>
      <p class="server w-48">Server</p>
      <p class="xp w-48">Expérience</p>
      <p class="messages w-48">Messages</p>
    </div>
    <!-- NORMAL RANK MODE  -->
    <div v-if="user_search.length < 1" class="player--list">
      <div v-for="(player, i) in players" :key="i" class="flex items-center">
        <p class="rank mr-12 font-bold text-lg w-[55px]">{{ player.rank }}</p>
        <PlayerRank :playerInfo="player" :rank="i + 1" />
      </div>
    </div>
    <!-- pagination  desktop -->
    <div
      v-if="user_search.length < 1 && players.length > 1"
      class="pagination ml-auto w-fit my-5 hidden md:flex"
    >
      <p class="cursor-pointer flex items-center" @click="changePage('prev')">
        <svg
          class="mr-3"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.3239 11.5053C20.3239 11.0358 19.9434 10.6553 19.4739 10.6553L6.19649 10.6553C5.72704 10.6553 5.34649 11.0358 5.34649 11.5053C5.34649 11.9747 5.72704 12.3553 6.19649 12.3553L19.4739 12.3553C19.9434 12.3553 20.3239 11.9747 20.3239 11.5053Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.1136 5.9252C9.78167 5.59325 9.24348 5.59325 8.91153 5.9252L4.61668 10.22C3.89421 10.9425 3.89421 12.1139 4.61668 12.8363L8.89185 17.1115C9.2238 17.4435 9.76199 17.4435 10.0939 17.1115C10.4259 16.7796 10.4259 16.2414 10.0939 15.9094L5.81876 11.6343C5.76018 11.5757 5.76018 11.4807 5.81876 11.4221L10.1136 7.12728C10.4456 6.79533 10.4456 6.25714 10.1136 5.9252Z"
            fill="white"
          />
        </svg>
        Prev
      </p>

      <p class="ml-[35px] cursor-pointer" v-if="pageCount > 2" @click="changePage(pageCount - 2)">
        {{ pageCount - 2 }}
      </p>
      <p class="ml-[35px] cursor-pointer" v-if="pageCount > 1" @click="changePage(pageCount - 1)">
        {{ pageCount - 1 }}
      </p>
      <p class="ml-[35px] cursor-pointer font-bold underline" @click="changePage(pageCount)">
        {{ pageCount }}
      </p>
      <p
        class="ml-[35px] cursor-pointer"
        v-if="pageCount < max_pages_count - 1"
        @click="changePage(pageCount + 1)"
      >
        {{ pageCount + 1 }}
      </p>
      <p
        class="ml-[35px] cursor-pointer"
        v-if="pageCount < max_pages_count - 2"
        @click="changePage(pageCount + 2)"
      >
        {{ pageCount + 2 }}
      </p>
      <p
        class="ml-[35px] cursor-pointer"
        v-if="pageCount < max_pages_count - 3"
        @click="changePage(pageCount + 3)"
      >
        {{ pageCount + 3 }}
      </p>
      <p class="ml-[15px]" v-if="pageCount < max_pages_count">...</p>
      <p
        class="ml-[15px] cursor-pointer"
        v-if="pageCount < max_pages_count"
        @click="changePage(max_pages_count)"
      >
        {{ max_pages_count }}
      </p>
      <p
        class="ml-[35px] cursor-pointer flex items-center"
        v-if="pageCount < max_pages_count"
        @click="changePage('next')"
      >
        Next
        <svg
          class="ml-3"
          width="18"
          height="13"
          viewBox="0 0 18 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.676269 6.49473C0.67627 6.96417 1.05683 7.34473 1.52627 7.34473L14.8037 7.34473C15.2731 7.34473 15.6537 6.96417 15.6537 6.49473C15.6537 6.02528 15.2731 5.64473 14.8037 5.64473L1.52627 5.64473C1.05683 5.64473 0.676269 6.02528 0.676269 6.49473Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8863 12.0747C11.2182 12.4067 11.7564 12.4067 12.0883 12.0747L16.3832 7.77989C17.1057 7.05742 17.1057 5.88606 16.3832 5.16359L12.108 0.888423C11.7761 0.556477 11.2379 0.556477 10.9059 0.888424C10.574 1.22037 10.574 1.75856 10.9059 2.09051L15.1811 6.36568C15.2397 6.42425 15.2397 6.51923 15.1811 6.57781L10.8863 10.8727C10.5543 11.2046 10.5543 11.7428 10.8863 12.0747Z"
            fill="white"
          />
        </svg>
      </p>
    </div>
    <!-- pagination  mobile -->
    <div
      v-if="user_search.length < 1 && players.length > 1"
      class="pagination flex ml-auto w-fit my-5 md:hidden"
    >
      <p class="cursor-pointer flex items-center" @click="changePage('prev')">
        <svg
          class="mr-3"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.3239 11.5053C20.3239 11.0358 19.9434 10.6553 19.4739 10.6553L6.19649 10.6553C5.72704 10.6553 5.34649 11.0358 5.34649 11.5053C5.34649 11.9747 5.72704 12.3553 6.19649 12.3553L19.4739 12.3553C19.9434 12.3553 20.3239 11.9747 20.3239 11.5053Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.1136 5.9252C9.78167 5.59325 9.24348 5.59325 8.91153 5.9252L4.61668 10.22C3.89421 10.9425 3.89421 12.1139 4.61668 12.8363L8.89185 17.1115C9.2238 17.4435 9.76199 17.4435 10.0939 17.1115C10.4259 16.7796 10.4259 16.2414 10.0939 15.9094L5.81876 11.6343C5.76018 11.5757 5.76018 11.4807 5.81876 11.4221L10.1136 7.12728C10.4456 6.79533 10.4456 6.25714 10.1136 5.9252Z"
            fill="white"
          />
        </svg>

        Prev
      </p>
      <p class="ml-[35px] cursor-pointer" v-if="pageCount > 1" @click="changePage(pageCount - 1)">
        {{ pageCount - 1 }}
      </p>
      <p class="ml-[35px] cursor-pointer font-bold underline" @click="changePage(pageCount)">
        {{ pageCount }}
      </p>
      <p class="ml-[15px]" v-if="pageCount < max_pages_count">...</p>
      <p
        class="ml-[15px] cursor-pointer"
        v-if="pageCount < max_pages_count"
        @click="changePage(max_pages_count)"
      >
        {{ max_pages_count }}
      </p>
      <p
        class="ml-[35px] cursor-pointer flex items-center"
        v-if="pageCount < max_pages_count"
        @click="changePage('next')"
      >
        Next
        <svg
          class="ml-3"
          width="18"
          height="13"
          viewBox="0 0 18 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.676269 6.49473C0.67627 6.96417 1.05683 7.34473 1.52627 7.34473L14.8037 7.34473C15.2731 7.34473 15.6537 6.96417 15.6537 6.49473C15.6537 6.02528 15.2731 5.64473 14.8037 5.64473L1.52627 5.64473C1.05683 5.64473 0.676269 6.02528 0.676269 6.49473Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8863 12.0747C11.2182 12.4067 11.7564 12.4067 12.0883 12.0747L16.3832 7.77989C17.1057 7.05742 17.1057 5.88606 16.3832 5.16359L12.108 0.888423C11.7761 0.556477 11.2379 0.556477 10.9059 0.888424C10.574 1.22037 10.574 1.75856 10.9059 2.09051L15.1811 6.36568C15.2397 6.42425 15.2397 6.51923 15.1811 6.57781L10.8863 10.8727C10.5543 11.2046 10.5543 11.7428 10.8863 12.0747Z"
            fill="white"
          />
        </svg>
      </p>
    </div>
    <!-- SEARCH RANK MODE  -->
    <div v-else class="player--list">
      <div v-for="(player, i) in players_search" :key="i" class="flex items-center">
        <p class="rank mr-12 font-bold text-lg w-[55px]">{{ player.rank }}</p>
        <PlayerRank :playerInfo="player" :rank="i + 1" />
      </div>
    </div>
  </div>
</template>

<script setup>
// import BackToPage from "@/components/BackToPage.vue";
import PlayerRank from "@/components/Leaderboard/PlayerRank.vue";
import { ref, onMounted, computed, watch, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
const backendApi = inject("backendApi");

const route = useRoute();
const router = useRouter();
let pageCount = ref(Number(route.params.number_page));
let guildId = ref(route.params.guildId);

// list of player for normal mode
let players = ref([]);

// list of player for search mode
let players_search = ref([]);
let time_state = ref(0);
let user_search = ref("");
let max_pages_count = ref(0);

// watch user search
watch(user_search, (new_search) => userSearchPlayer(new_search));
watch(pageCount, (new_count) => router.replace({ params: { number_page: new_count } }));

const choosePage = (i) => {
  if (i !== "next" && i !== "prev") return i;
  else {
    return i == "next" ? pageCount.value + 1 : pageCount.value - 1;
  }
};

const changePage = (i) => {
  // get position diference of top window and target scroll
  const getTopPosition = (target) => {
      return target.getBoundingClientRect().top + window.pageYOffset;
    },
    targetLink = document.querySelector("#leaderBoardLink");
  // scroll to target tools bar  position
  window.scrollTo({ top: getTopPosition(targetLink), behavior: "smooth" });
  backendApi(`/leaderboard?guildId=${guildId.value}&page=${choosePage(i)}`).then((response) => {
    pageCount.value = choosePage(i);
    players.value = response.data.players;
  });
};
const switchTimeState = () => {
  // get infos & change state when infos is ok
  time_state.value = time_state.value == 0 ? 1 : 0;
};
const allTimeComputed = computed(() =>
  time_state.value == 0 ? "bg-primary" : "hover:bg-[#5A30E5]"
);
const lastDaysComputed = computed(() =>
  time_state.value == 1 ? "bg-primary" : "hover:bg-[#5A30E5]"
);

const userSearchPlayer = (value) =>
  backendApi(`/leaderboard/search?username=${value}&guildId=${guildId.value}`).then(
    (response) => (players_search.value = response.data.players)
  );

onMounted(() => {
  backendApi(`/leaderboard?guildId=${guildId.value}&page=${pageCount.value}`).then((response) => {
    max_pages_count.value = response.data.page_count;
    players.value = response.data.players;
  });
});
</script>
<style lang="scss">
#leadboard {
  @apply px-8 pb-8;

  .leadboard--hero {
    @apply mb-5;

    .back-to-page {
      @apply mb-8;
    }

    h1 {
      @apply text-2xl font-bold mb-4;
      @screen lg {
        @apply text-5xl;
      }
    }

    p {
      @apply text-base text-gray-5 leading-9 font-semibold;
    }
  }

  .leadboard--tools {
    @screen lg {
      @apply flex justify-between mb-12;
    }
    .switch-time {
      @apply bg-bg-3;
    }

    .search-rank {
      order: -1;
      @screen lg {
        @apply mb-0;
      }
      p {
        @apply text-xs text-white mb-2;
      }
      div {
        @apply bg-bg-3 flex items-center rounded w-full p-2;
        @screen md {
          @apply w-fit;
        }
        input {
          @apply bg-transparent text-sm ml-3 outline-none;
        }
      }
    }
  }
}
</style>
