<template>
  <ModalWrapper :closeModal="true" name="Twitter">
    <Wrapper :cross="false" v-slot="{ closeModal }" class="min">
      <div
        class="black__overlay absolute bg-[#212330] h-full top-0 left-0 w-full hidden lg:block max-w-[460px] rounded-l-[32px]"
      ></div>
      <div
        class="w-fit h-screen lg:max-h-[650px] pl-4 pr-4 flex overflow-x-hidden"
        :class="{ 'lg:max-h-[473px]': modalStep == 'confirm' }"
      >
        <div class="hidden lg:flex wrapper--back__desktop w-screen max-w-[460px] z-10 flex-col">
          <div
            v-if="modalStep == 'confirm'"
            @click="closeModal()"
            class="pt-10 w-[26.5px] flex items-center cursor-pointer"
          >
            <img src="/icon/arrow-left.png" alt />
            <span class="pl-2 text-xs font-normal">Back</span>
          </div>
          <AllSvg class="m-auto" svg="chain_desktop" />
        </div>
        <AllSvg svg="chain_mobile" class="lg:hidden" />

        <!-- bottom content  -->
        <div class="flex transition-transform duration-300 ease-linear">
          <div class="w-full lg:w-screen lg:max-w-[500px] lg:flex lg:flex-col lg:justify-center">
            <div
              @click="closeModal()"
              class="lg:hidden pt-10 w-[26.5px] flex items-center cursor-pointer"
            >
              <img src="/icon/arrow-left.png" alt />
              <span class="pl-2 text-xs font-normal">Back</span>
            </div>
            <div v-if="modalStep != 'confirm'">
              <section class="pt-6">
                <p class="font-semibold text-[28px] flex items-center">Connect your</p>
                <p class="font-semibold text-[28px] flex items-center">
                  twitter
                  <svg
                    class="ml-3"
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26 2.9375C25.043 3.36328 24.0156 3.64844 22.9375 3.77734C24.0391 3.11719 24.8828 2.07031 25.2812 0.824219C24.25 1.4375 23.1094 1.88281 21.8945 2.12109C20.9219 1.08594 19.5352 0.4375 18 0.4375C15.0547 0.4375 12.6641 2.82422 12.6641 5.76953C12.6641 6.1875 12.7148 6.59766 12.8047 6.98438C8.37109 6.76172 4.44141 4.64062 1.80859 1.41016C1.35156 2.19922 1.08984 3.11328 1.08984 4.09375C1.08984 5.94531 2.02734 7.57812 3.46094 8.53125C2.58594 8.50391 1.76172 8.26563 1.04297 7.86719C1.04297 7.89063 1.04297 7.91016 1.04297 7.93359C1.04297 10.5195 2.88281 12.6758 5.32422 13.1641C4.875 13.2852 4.40234 13.3516 3.91797 13.3516C3.57422 13.3516 3.23828 13.3164 2.91406 13.2539C3.59375 15.375 5.5625 16.918 7.89844 16.9609C6.07031 18.3906 3.77344 19.2422 1.27344 19.2422C0.84375 19.2422 0.417969 19.2188 0 19.168C2.35938 20.6836 5.16406 21.5664 8.17578 21.5664C17.9883 21.5664 23.3516 13.4375 23.3516 6.38672C23.3516 6.15625 23.3477 5.92578 23.3359 5.69922C24.3789 4.94531 25.2852 4.00781 26 2.9375Z"
                      fill="white"
                    />
                  </svg>
                </p>
              </section>
              <p class="pt-2 text-[#CAC7E0] text-base leading-6 pb-4 max-w-[315px]">
                You need to connect your discord account to recover your xp !
              </p>
              <div class="wrapper--xp flex items-center mb-3 mt-11">
                <span
                  class="h-[30px] w-[30px] flex items-center justify-center mr-3 bg-[#3C3E4D] text-xs rounded-full"
                  >1</span
                >
                <p>Recover your XP</p>
              </div>
              <div class="wrapper--xp flex items-center mb-3">
                <span
                  class="h-[30px] w-[30px] flex items-center justify-center mr-3 bg-[#3C3E4D] text-xs rounded-full"
                  >2</span
                >
                <p>Link your twitter account</p>
              </div>
              <Cta class="mt-12 w-fit" @click="redirectionToTwitter">
                <span class="mx-3">Connect to your twitter</span></Cta
              >
              <p class="mt-5 text-xs">
                No account? <a href="https://twitter.com/" class="text-[#9A9BFF]">Register</a>
              </p>
            </div>
            <div v-else>
              <p class="font-semibold text-[28px] flex items-center">
                Your twitter is<br />
                successfully linked
                <svg class="w-7 mt-10 ml-3" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m13 0c-7.1797 0-13 5.8203-13 13s5.8203 13 13 13 13-5.8203 13-13c-0.0084-7.1762-5.8238-12.992-13-13z"
                    fill="#79D8A5"
                  />
                  <path
                    d="m20.568 8.8535-7.6609 10.029c-0.1827 0.2339-0.4552 0.3871-0.7561 0.4253-0.301 0.0382-0.6052-0.0419-0.8444-0.2223l-5.4706-4.2195c-0.48274-0.3726-0.56091-1.0523-0.17459-1.518s1.0908-0.5411 1.5736-0.1684l4.5618 3.5209 6.9693-9.1246c0.2285-0.33084 0.627-0.51375 1.0373-0.47611 0.4102 0.03763 0.7658 0.28972 0.9256 0.65617 0.1598 0.36646 0.098 0.78798-0.161 1.0972z"
                    fill="#fff"
                  />
                </svg>
              </p>
              <p class="pt-6 text-gray-5">
                Your twitter account is now linked<br />
                with discord
              </p>
              <Cta class="mt-12 w-fit" @click="closeModal">
                <span class="mx-11">Back to discord</span></Cta
              >
            </div>
          </div>
        </div>

        <!-- bottom content  -->
      </div>
    </Wrapper>
  </ModalWrapper>
</template>

<script setup>
import ModalWrapper from "@/components/ModalWrapper.vue";
import AllSvg from "@/components/AllSvg.vue";
import Wrapper from "@/components/Modals/Wrapper.vue";
import Cta from "@/components/Cta.vue";
import { useGetUserInformations } from "@/hooks/getUserInformations";
import { inject, computed } from "vue";
import { useRoute } from "vue-router";

const ENV = inject("ENV");
const route = useRoute();

useGetUserInformations();

const modalStep = computed(() => route.query.modalStep);

const redirectionToTwitter = async () => {
  document.location.href = `${ENV.BACK_END_URI}/twitter/sso?discordId=${encodeURIComponent(
    route.query.discordId
  )}&guildId=${encodeURIComponent(route.query.guildId)}`;
};
</script>

<style lang="scss" scoped>
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-leave-active {
  position: absolute;
}
</style>
