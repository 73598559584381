<template>
  <div class="bg-[#282A3A] min-h-[450px] block min-w-[300px] rounded-[32px] relative wrapper">
    <img
      v-if="cross"
      @click="close()"
      class="right-4 top-4 absolute cursor-pointer"
      src="@/assets/modal/cross.svg"
      alt=""
    />
    <div class="p-6">
      <slot v-bind:closeModal="close"></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import useModal from "@/hooks/useCloseModal";

const { close } = useModal()

defineProps({
  cross: {
    type: Boolean,
    default: true,
  },
});
</script>
