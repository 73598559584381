<template>
  <div class="px-8 pb-20">
    <div class="pt-8 text-[26px] font-bold sm:pt-32 sm:text-5xl sm:leading-relaxed md:flex">
      <p>
				Build an engaged<br />
				community !
      </p>
			<div class="hidden md:block ">
				<img src="@/assets/Home/hero.png"  class="absolute right-0 w-1/3 lg:w-1/2 lg:max-w-[455px] xl:w-3/6 xl:max-w-[655px]" alt="">
			</div>
    </div>
    <div>
			<div>
				<p class="pt-5 text-gray-5 sm:text-xl sm:leading-loose">
					Interact with your community by<br class="hidden sm:hidden" />
					providing a unique<br class="hidden sm:block"> and customizable<br class="sm:hidden" />
					gamified experience
				</p>
			</div>
      <section class="pt-6 sm:pt-10">
        <Cta @click="store.currentModal = 'ChosesServer'" class="w-full max-w-[200px] h-14 font-semibold"><span class="py-2">Add to Discord</span></Cta>
      </section>
    </div>

		<div class="h-[40vh] block md:hidden relative">
			<img src="@/assets/Home/hero-mobile.png"  class="absolute right-[-2rem] w-full md:w-1/2" alt="">
		</div>

    <div>
      <p class="pt-10 text-center font-bold text-xl sm:text-3xl sm:pt-20 xl:pt-[400px] lg:pt-44">
        Must haves <span class="text-primary-light">features</span>
      </p>

      <Cards />

      <p class="pt-10 text-center font-bold text-xl pb-10 sm:text-4xl sm:pt-32 xl:pt-40 xl:pb-14">
        Invite <span class="text-primary-light">Thales</span> to your Discord server
      </p>

			<CardsPrices />

    </div>
  </div>
</template>

<script setup>
import Cta from "@/components/Cta.vue";
import Cards from "@/components/Home/Cards";
import CardsPrices from "@/components/Home/CardPrices";
import { globalStore } from "@/stores/global";
const store = globalStore();

</script>

<style lang="scss" scoped></style>
