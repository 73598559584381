<template>
  <div class="font-body">
    <div class="sm:flex max-w-7xl mx-auto px-8 sm:justify-between pt-8">
      <img
        @click="$router.push({ name: 'Home' })"
        src="/thales-logo.png"
        class="w-[83px] mx-auto py-2 pt-5 sm:relative sm:mx-0 sm:w-[130px] cursor-pointer"
        alt
      />
      <div>
        <Cta v-show="$route.name == 'Home'" @click="connexion" class="hidden sm:block">
          <span class="flex px-1">
            <img src="/icon/profile.png" alt class="pr-2" />
            <span class="font-medium pr-3">Connect to Discord</span>
          </span>
        </Cta>
      </div>
    </div>
    <div class="font-body">
      <Modals></Modals>
    </div>
    <div class="font-body max-w-7xl mx-auto">
      <router-view />
    </div>
    <div class="w-full bg-[#1D1D26] h-fit">
      <div class="sm:flex max-w-7xl mx-auto sm:justify-between p-8">
        <img src="/thales-logo.png" class="w-[83px] mx-auto py-2 pt-5 sm:relative sm:mx-0" alt />
        <p class="text-[#788CA0]">
          Lovingly published by Thales.
          <br />Copyright © 2022
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { globalStore } from "@/stores/global";
import Cta from "@/components/Cta.vue";
import Modals from "@/views/Modals.vue";
import { useRoute, useRouter } from "vue-router";
import { watch, inject } from "vue";
const router = useRouter();
const route = useRoute();
const store = globalStore();

const ENV = inject("ENV");
const backendApi = inject("backendApi");

watch(() => {
  const code = route.query.code;
  console.log(code);

  if (code) {
    backendApi.get(`/discord/login/${code}`).then(async ({ data }) => {
      store.accessToken = data.access_token;
      router.replace({ query: {} });
    });
  }
}, [route.query.code]);

const connexion = () => {
  document.location.href = `${ENV.BACK_END_URI}/discord/`;
};
</script>

<style>
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
  @apply bg-gray-1 text-white;
}
body {
  width: 100vw;
  overflow-x: hidden;
}
</style>
