import { useRoute, useRouter } from "vue-router";

export function useModal() {
  const router = useRouter(),
    route = useRoute();
  const modals = ["WalletMaiar"];

  const changeRoute = (modal) => {
    router.push({
      query: {
        ...route.query,
        modalOpen: modal,
      },
    });
  };

  const close = () => changeRoute("");

  const open = (modal) => {
    // test if parameters is existing modal
    function testModal() {
      return modals.includes(modal);
    }

    testModal() ? changeRoute(modal) : alert(`cette modal ${modal.toUpperCase()} n'existe pas`);
  };
  return {
    close,
    open,
  };
}

export default useModal;
